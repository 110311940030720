<template>
  <div>
    <q-card class="my-card fixed-center" style="width: 500px; max-width: 80vw;">
      <q-card-section>
        <div class="text-h6 text-weight-light">Login</div>
        <q-separator spaced />
        <q-form
          @submit="loginUser()"
          class="q-gutter-md q-mt-sm"
        >
        <div class="q-ma-sm row">
          <div class="q-ma-sm col-12">
            <q-input
              v-model="login.email"
              :dense="true"
              label="Email"
              type="email"
              outlined
            />
          </div>
          <div class="q-ma-sm col-12">
            <q-input
              v-model="login.password"
              :dense="true"
              label="Password"
              type="password"
              outlined
            />
          </div>
        </div>
          <div>
            <q-btn label="Login" type="submit" color="dark"/>
            <q-btn label="Register" class="q-ml-sm" type="submit" color="dark" href="https://bwauthweb.azurewebsites.net/register"/>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import authService from '../services/authService'
import { defineComponent } from 'vue'
import notifyService from '../services/notifyService'

export default defineComponent({
  data () {
    return {
      token: String(this.$route.params.token),
      loginResponse: {},
      login: {},
      layout: false
    }
  },
  methods: {
    async loginUser () {
      authService.login(this.login)
        .then(res => {
          localStorage.setItem('access_token', res.data.token)
          localStorage.setItem('username', res.data.email)
          localStorage.setItem('roles', res.data.roles.toString())
          window.location.replace('/')
        })
        .catch(error => {
          notifyService.Error(error)
        })
    }
  }
})
</script>
